import React, { useCallback, useContext, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import FolderIcon from 'assets/FolderIcon';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import { DND_FOLDER, DND_VIDEO } from 'utils/constants';
import { getFolderRelativeUrl } from 'utils/folderUtils';

import { RoutingContext } from '../PatchedTideSubnav/util';

import ExpandCollapseButton from './ExpandCollapseButton';
import isNavTreeLinkActive from './isNavTreeLinkActive';

import './nodeContentRenderer.css';

const NodeContentRender = ({
  node,
  path,
  depth,
  treeIndex,
  canDropFolderNode,
  onDropVideo,
  onDropFolder,
  onLongHoverFolder,
  toggleChildrenVisibility,
}) => {
  const NavLink = useContext(RoutingContext);

  const [{ isDraggingFolder }, dragRef, dragPreviewRef] = useDrag({
    item: { type: DND_FOLDER, node },
    canDrag: () => depth > 0,
    collect: (monitor) => ({ isDraggingFolder: monitor.isDragging() }),
  });

  const [{ isOverFolder, canDropFolder }, dropRef] = useDrop({
    accept: [DND_VIDEO, DND_FOLDER],
    canDrop: (item) =>
      item.type === DND_VIDEO || canDropFolderNode({ node: item.node, nextParent: node }),
    drop: (item) => {
      if (item.type === DND_VIDEO) {
        onDropVideo({ videoId: item.videoId, folderId: node.id });
      }

      if (item.type === DND_FOLDER) {
        const { id, title, privacyStatus } = item.node;
        const parentFolder = node.id;
        onDropFolder({ id, title, privacyStatus, parentFolder });
      }
    },
    collect: (monitor) => ({
      isOverFolder: monitor.isOver(),
      canDropFolder: monitor.canDrop(),
    }),
  });

  const stillOver = useRef(null);
  stillOver.current = isOverFolder;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onHover = useCallback(
    debounce(() => {
      if (stillOver.current) {
        onLongHoverFolder({ node, path });
      }
    }, 1000),
  );

  if (isOverFolder) {
    onHover();
  }

  const isDropTargetActive = isOverFolder && canDropFolder;

  return (
    <div
      className={cx('folder-node', {
        'folder-node--dragging': isDraggingFolder,
        'folder-node--drop-hover': isDropTargetActive,
      })}
      key={node.id + isDropTargetActive}
      ref={dropRef}
    >
      <span className="folder-node__content" ref={dragRef}>
        <NavLink
          className="tc-subnav-link"
          activeClassName="tc-subnav-link--active"
          to={getFolderRelativeUrl(node)}
          isActive={isNavTreeLinkActive(node)}
          target="_self"
          exact
        >
          {Array(depth)
            .fill()
            .map((_, i) => (
              <div className="folder-node__spacer" key={i} />
            ))}
          <span className="folder-node__title" ref={dragPreviewRef}>
            {node.parentFolder && <FolderIcon />}
            {node.title}
          </span>
          <ExpandCollapseButton
            expanded={node.expanded}
            disabled={!node.children}
            onClick={(e) => {
              e.preventDefault();
              toggleChildrenVisibility({ node, path, treeIndex });
            }}
          />
        </NavLink>
      </span>
    </div>
  );
};

NodeContentRender.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    parentFolder: PropTypes.number,
    children: PropTypes.arrayOf(PropTypes.object),
    expanded: PropTypes.bool,
  }).isRequired,
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
  depth: PropTypes.number.isRequired,
  treeIndex: PropTypes.number.isRequired,
  canDropFolderNode: PropTypes.func.isRequired,
  onDropVideo: PropTypes.func.isRequired,
  onLongHoverFolder: PropTypes.func.isRequired,
  toggleChildrenVisibility: PropTypes.func.isRequired,
  onDropFolder: PropTypes.func,
};

export default NodeContentRender;
