import React, { useContext, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import composeRefs from '@seznam/compose-react-refs';
import cx from 'classnames';

import FolderContext from 'context/FolderContext';
import { DND_FOLDER, DND_VIDEO } from 'utils/constants';
import { canMoveFolder } from 'utils/folderUtils';

function FolderRowDragWrapper({ folder, children }) {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DND_FOLDER,
      node: folder,
      element: React.cloneElement(children.props.children[0]),
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const { folders, onMoveFolder, onMoveVideoToFolder } = useContext(FolderContext);

  const [dropProps, drop] = useDrop({
    accept: [DND_VIDEO, DND_FOLDER],
    canDrop: (item) => item.type === DND_VIDEO || canMoveFolder(item.node, folder, folders),
    drop: (item) => {
      if (item.type === DND_VIDEO) {
        onMoveVideoToFolder({ videoId: item.videoId, folderId: folder.id });
      } else {
        onMoveFolder({
          id: item.node.id,
          title: item.node.title,
          parentFolder: folder.id,
          privacyStatus: item.node.privacyStatus,
        });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return React.cloneElement(children, {
    ref: composeRefs(drag, drop),
    className: cx({
      'video-library-table__dragging-row': isDragging,
      'video-library__folder-drop-target--active': dropProps.isOver && dropProps.canDrop,
    }),
  });
}

export default FolderRowDragWrapper;
