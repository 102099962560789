import React from 'react';

import PropTypes from 'prop-types';

import { IconButton, ToolTip } from '@driftt/tide-core';

import { cloneVideo } from 'api';
import { generateShareUrl } from 'utils';

const InlineCloneButton = ({ videoId }) => {
  const handleCloneVideo = () => {
    cloneVideo(videoId)
      .then((response) => {
        if (response) {
          window.open(generateShareUrl(response.data.shareUrl) + '?cloned');
        }
      })
      .catch((err) => {
        Error(err);
      });
  };

  return (
    <ToolTip flow="up" render={() => 'Clone with my settings'}>
      <IconButton
        className="inline-clone-button"
        icon="clone-1"
        size="large"
        onClick={handleCloneVideo}
      />
    </ToolTip>
  );
};

InlineCloneButton.propTypes = {
  videoId: PropTypes.number,
}

export default InlineCloneButton;
