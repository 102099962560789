import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { ActionDropdown, IconButton, ToolTip } from '@driftt/tide-core';

import { deleteVideo, makeOutreachSnippet } from 'api';
import ConfirmDeleteModal from 'components/confirmDeleteModal';
import FullPageSpinner from 'components/fullPageSpinner';
import FolderContext from 'context/FolderContext';
import { UserContext } from 'context/UserContext';
import { downloadVideo, generateShareUrl } from 'utils';

import AddToFolderModal from './AddToFolderModal';

import './InlineVideoActionDropdown.css';

const InlineVideoActionDropdown = ({
  isUserUploadedVideo,
  publicUrl,
  onDeleteVideo,
  shareUrl,
  videoId,
  onEditTitleClicked,
}) => {
  const { userHasFeature, isFreeVideoUser } = useContext(UserContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddToFolderModal, setShowAddToFolderModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [flowUp, setFlowUp] = useState(false);
  const { folders, onMoveVideoToFolder } = useContext(FolderContext);

  const handleConfirmDelete = async (videoId) => {
    setShowSpinner(true);
    try {
      await deleteVideo({ videoId });
      onDeleteVideo();
    } catch (err) {
      Error(err);
    } finally {
      setShowSpinner(false);
      setShowDeleteModal(false);
    }
  };

  const handleConfirmAddToFolder = (folderId) => {
    onMoveVideoToFolder({ folderId, videoId });
    setShowAddToFolderModal(false);
  };

  const handleMouseEnter = (e) => {
    const el = e.currentTarget;
    const isMenuOpen = el.querySelector('.tide-action-dropdown-actions') !== null;

    if (!isMenuOpen) {
      const rect = el.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      setFlowUp(spaceBelow < 236);
    }
  };

  return (
    <>
      <div onMouseEnter={handleMouseEnter}>
        <ActionDropdown
          flowUp={flowUp}
          className="inline-video-action-dropdown"
          trigger={
            <ToolTip flow="up" render={() => 'More options'}>
              <IconButton
                className="inline-video-action-dropdown-button"
                icon="meatballs"
                size="large"
              />
            </ToolTip>
          }
          align="right"
          actions={[
            {
              label: 'Move to folder...',
              action: (hideDropdown) => {
                hideDropdown();
                setShowAddToFolderModal(true);
              },
              disabled: !folders || !folders.length,
            },
            isUserUploadedVideo
              ? {
                  label: 'Upload video thumbnail',
                  action: (hideDropdown) => {
                    hideDropdown();
                    window.open(generateShareUrl(shareUrl) + '?add_thumbnail');
                  },
                }
              : null,
            userHasFeature('OUTREACH_INTEGRATION')
              ? {
                  label: 'Create Outreach snippet',
                  action: (hideDropdown) => {
                    hideDropdown();
                    makeOutreachSnippet(videoId);
                  },
                }
              : null,
            {
              label: 'Create GIF from video',
              action: (hideDropdown) => {
                hideDropdown();
                window.open(generateShareUrl(shareUrl) + '?create_gif');
              },
            },
            {
              label: 'Rename video',
              action: (hideDropdown) => {
                hideDropdown();
                onEditTitleClicked(videoId);
              },
            },
            !isFreeVideoUser()
              ? {
                  label: 'Download video',
                  action: (hideDropdown) => {
                    hideDropdown();
                    downloadVideo(videoId);
                  },
                }
              : null,
            {
              label: 'Customer preview',
              action: (hideDropdown) => {
                hideDropdown();
                window.open(publicUrl);
              },
            },
            {
              label: 'Delete video',
              action: (hideDropdown) => {
                hideDropdown();
                setShowDeleteModal(true);
              },
            },
          ].filter(Boolean)}
        />
      </div>
      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        onClickConfirm={() => handleConfirmDelete(videoId)}
        onClickCancel={() => setShowDeleteModal(false)}
        target="video"
      />
      <AddToFolderModal
        isOpen={showAddToFolderModal}
        onClickConfirm={handleConfirmAddToFolder}
        onClickCancel={() => setShowAddToFolderModal(false)}
      />
      <FullPageSpinner show={showSpinner} />
    </>
  );
};

InlineVideoActionDropdown.propTypes = {
  isUserUploadedVideo: PropTypes.bool.isRequired,
  publicUrl: PropTypes.string.isRequired,
  shareUrl: PropTypes.string.isRequired,
  onDeleteVideo: PropTypes.func.isRequired,
  videoId: PropTypes.number.isRequired,
  onEditTitleClicked: PropTypes.func.isRequired,
};

export default InlineVideoActionDropdown;
