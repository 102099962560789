import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '@driftt/tide-core';

import './ExpandCollapseButton.css';

function ExpandCollapseButton({ expanded, onClick, ...otherProps }) {
  const [keyboardFocus, setKeyboardFocus] = useState(false);

  const handleKeyUp = (e) => {
    if (e.key === 'Tab') {
      setKeyboardFocus(true);
    }
  };

  return (
    <button
      type="button"
      className={cx('expand-collapse-button', {
        'expand-collapse-button--expanded': expanded,
        'expand-collapse-button--keyboard-focus': keyboardFocus,
      })}
      aria-label={expanded ? 'Collapse' : 'Expand'}
      onClick={onClick}
      onKeyUp={handleKeyUp}
      onBlur={() => setKeyboardFocus(false)}
      {...otherProps}
    >
      <Icon name="arrow-1-left" width="10" height="10" />
    </button>
  );
}

ExpandCollapseButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default ExpandCollapseButton;
