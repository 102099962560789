import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Div } from '@driftt/tide-core';
import ExpandCollapseButton from '../../FoldersSubnav/ExpandCollapseButton';
import { getChildNodes } from '../util';

import './styles.css';

const ParentNode = (props) => {
  const [expanded, toggleExpanded] = useState(props.expanded);

  const { label, className, onClick } = props;
  const nodes = getChildNodes(props.nodes);

  const toggleClick = () => {
    onClick && onClick();
    toggleExpanded(!expanded);
  };

  return (
    <Div
      className={classnames(
        'tc-subnav-node-group',
        { 'tc-subnav-node-group--collapsed': !expanded },
        className,
      )}
    >
      <Div className="tc-subnav-node-group-header" onClick={toggleClick}>
        <Div className="tc-subnav-node-group-toggle">
          {label}
          <ExpandCollapseButton expanded={expanded} disabled={!label} />
        </Div>
      </Div>
      <Div className="tc-subnav-node-group-drawer-wrapper">
        <Div className="tc-subnav-node-group-drawer">{nodes}</Div>
      </Div>
    </Div>
  );
};

ParentNode.propTypes = {
  label: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  nodes: PropTypes.array.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default ParentNode;
