import * as React from 'react';
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';

import * as PropTypes from 'prop-types';
import { PrivacyStatus } from 'types/interfaces';

import { P,Strong } from '@driftt/tide-core';

import FolderContext from 'context/FolderContext';
import useConfirmation from 'hooks/useConfirmation';
import { findFolderById } from 'utils/folderUtils';

import nodeContentRenderer from './nodeContentRenderer';
import treeNodeRenderer from './treeNodeRenderer';
import useTreeData from './useTreeData';

export interface FolderNavTreeProps {
  isTeamLibrary: boolean;
}

const FolderNavTree: React.FC<FolderNavTreeProps> = ({ isTeamLibrary }) => {
  const { treeData, handleChange, expandNode, canDropFolderNode } = useTreeData({ isTeamLibrary });

  const { folder, folders, onMoveFolder, onMoveVideoToFolder } = React.useContext(FolderContext);

  const [confirmHideVideoFromTeam, ConfirmHideVideoFromTeamModal] = useConfirmation();

  const handleDropVideo = async ({ videoId, folderId }) => {
    // @ts-ignore
    const currPrivacy = folder.privacyStatus;
    // @ts-ignore
    const destPrivacy = findFolderById(folderId, folders)?.privacyStatus;

    if (currPrivacy === PrivacyStatus.Team && destPrivacy === PrivacyStatus.Individual) {
      const confirmed = await confirmHideVideoFromTeam();

      if (!confirmed) {
        return;
      }
    }

    onMoveVideoToFolder({ videoId, folderId });
  };

  // See https://github.com/frontend-collective/react-sortable-tree#props
  return (
    <>
      <SortableTree
        className="folder-tree"
        treeData={treeData}
        onChange={handleChange}
        isVirtualized={false}
        treeNodeRenderer={treeNodeRenderer}
        nodeContentRenderer={nodeContentRenderer}
        generateNodeProps={({ node }) => ({
          canDropFolderNode,
          onLongHoverFolder: expandNode,
          onDropFolder: onMoveFolder,
          onDropVideo: handleDropVideo,
        })}
      />
      <ConfirmHideVideoFromTeamModal title="Are you sure?" size="small">
        <Strong>Hold up! You’re about to remove this video from your team library.</Strong>
        <P>
          By moving this video to your library, your team will no longer be able to see it ir share
          it.
        </P>
      </ConfirmHideVideoFromTeamModal>
    </>
  );
};

FolderNavTree.propTypes = {
  isTeamLibrary: PropTypes.bool.isRequired,
};

export default FolderNavTree;
