import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { UserContext } from 'context/UserContext';
import { SOURCE_TYPE_GIF } from 'utils/constants';

import GifActions from './GifActions';
import VideoActions from './VideoActions';

const isGif = (video) => video.sourceType === SOURCE_TYPE_GIF;

function Actions({ video, onDeleteVideo, onEditTitleClicked }) {
  const { user } = useContext(UserContext);
  return isGif(video) ? (
    <GifActions
      videoId={video.id}
      onDeleteVideo={onDeleteVideo}
      isTeammateVideo={user.id !== video.user}
      onEditTitleClicked={onEditTitleClicked}
    />
  ) : (
    <VideoActions
      isUserUploadedVideo={Boolean(JSON.parse(video.extraData).upload_source)}
      publicUrl={video.publicUrl}
      onDeleteVideo={onDeleteVideo}
      shareUrl={video.shareUrl}
      thumbnailUrl={
        video.thumbnailGifUrlForCopyLink || video.thumbnailUrl || video.thumbnailDefault
      }
      videoId={video.id}
      videoTitle={video.title}
      isTeammateVideo={user.id !== video.user}
      onEditTitleClicked={onEditTitleClicked}
    />
  );
}

Actions.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number.isRequired,
    shareUrl: PropTypes.string.isRequired,
    extraData: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    sourceType: PropTypes.number.isRequired,
    user: PropTypes.number,
    publicUrl: PropTypes.string,
    thumbnailGifUrlForCopyLink: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    thumbnailDefault: PropTypes.string,
  }),
  onDeleteVideo: PropTypes.func.isRequired,
  onEditTitleClicked: PropTypes.func.isRequired,
};

export default Actions;
