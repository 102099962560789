import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Div,Footer, H4, Input, Modal } from '@driftt/tide-core';

import ModalPortal from 'components/modalPortal';
import SelectionBlock from 'components/selectionBlock';
import FolderContext from 'context/FolderContext';
import { UserContext } from 'context/UserContext';

import './AddToFolderModal.css';

function AddToFolderModal({ isOpen, onClickConfirm, onClickCancel }) {
  const { userHasFeature } = useContext(UserContext);
  const [folderId, setFolderId] = useState(null);
  const { folders } = useContext(FolderContext);

  const personalOptions = folders.filter((folder) => folder.privacyStatus === 1);
  const teamOptions = folders.filter((folder) => folder.privacyStatus === 2);

  const handleCancelClick = () => {
    setFolderId(null);
    onClickCancel();
  };

  return (
    <ModalPortal>
      <Modal
        className="add-to-folder"
        title="Move to folder"
        size="small"
        open={isOpen}
        onClose={handleCancelClick}
      >
        <Div className="folder-list-container">
          <H4>{userHasFeature('HAS_TEAM_LIBRARY') ? 'Personal folders' : 'Choose a folder'}</H4>
          {personalOptions.map((option, i) => (
            <SelectionBlock
              key={i}
              as="label"
              selected={false}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Input
                type="radio"
                name="folder"
                checked={option.id === folderId}
                onChange={() => setFolderId(option.id)}
              />
              <span className="add-to-folder__option-label">{option.title}</span>
            </SelectionBlock>
          ))}
          {teamOptions.length > 0 && <H4>Team folders</H4>}
          {teamOptions.map((option, i) => (
            <SelectionBlock
              key={i}
              as="label"
              selected={false}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Input
                type="radio"
                name="folder"
                checked={option.id === folderId}
                onChange={() => setFolderId(option.id)}
              />
              <span className="add-to-folder__option-label">{option.title}</span>
            </SelectionBlock>
          ))}
        </Div>
        <Footer>
          <Button type="primary" onClick={() => onClickConfirm(folderId)} disabled={!folderId}>
            Confirm
          </Button>
          <Button type="tertiary" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Footer>
      </Modal>
    </ModalPortal>
  );
}

AddToFolderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
};

export default AddToFolderModal;
