import { Children, cloneElement } from 'react';

import PropTypes from 'prop-types';

function TreeNodeRenderer({ lowerSiblingCounts, children }) {
  return Children.map(children, (child) =>
    cloneElement(child, {
      depth: lowerSiblingCounts.length - 1,
    }),
  );
}

TreeNodeRenderer.propTypes = {
  lowerSiblingCounts: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

export default TreeNodeRenderer;
