import React from 'react';

import PropTypes from 'prop-types';

import { Avatar, Div, P } from '@driftt/tide-core';

import { lookupUser } from 'utils';

const CloningUsers = ({ allUsers, cloningUsers, isTeammateVideo }) => {
  return (
    <Div className="video-library-row__cloning-users">
      {!cloningUsers || cloningUsers.length === 0 ? (
        <P className="video-library-row__cloning-users-empty-state">
          {isTeammateVideo && <em>Be the first to clone!</em>}
        </P>
      ) : (
        <>
          {cloningUsers[0] && (
            <Avatar className="cloning-user-1" user={lookupUser(allUsers, cloningUsers[0])} />
          )}
          {cloningUsers[1] && (
            <Avatar className="cloning-user-2" user={lookupUser(allUsers, cloningUsers[1])} />
          )}
          {cloningUsers[2] && (
            <Avatar className="cloning-user-3" user={lookupUser(allUsers, cloningUsers[2])} />
          )}
          {cloningUsers.length > 3 && (
            <P className="cloning-user-overflow">{`+ ${cloningUsers.length - 3} more`}</P>
          )}
        </>
      )}
    </Div>
  );
};

CloningUsers.propTypes = {
  allUsers: PropTypes.array,
  cloningUsers: PropTypes.array,
  isTeammateVideo: PropTypes.bool,
}

export default CloningUsers;
