import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { IconButton, ToolTip } from '@driftt/tide-core';

import copyToClipboard from 'utils/copyToClipboard';

const InlineCopyLinkAndGifButton = ({ publicUrl, thumbnailUrl, videoTitle, isTeammateVideo }) => {
  const timer = useRef(null);
  const [justCopied, setJustCopied] = useState(false);

  const handleClick = (e) => {
    if (justCopied) {
      return;
    }

    copyToClipboard({ publicUrl, thumbnailUrl, videoTitle });
    setJustCopied(true);
    timer.current = setTimeout(() => setJustCopied(false), 1000);
  };

  useEffect(() => () => clearTimeout(timer.current), []);

  return (
    <ToolTip
      flow="up"
      render={() => {
        if (justCopied) {
          return 'Copied!';
        } else if (isTeammateVideo) {
          return 'Copy original link + GIF';
        } else {
          return 'Copy link + GIF';
        }
      }}
    >
      <IconButton
        className="inline-copy-link-and-gif-button"
        icon={justCopied ? 'check' : 'link-1'}
        size="large"
        onClick={handleClick}
      />
    </ToolTip>
  );
};

InlineCopyLinkAndGifButton.propTypes = {
  publicUrl: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
  isTeammateVideo: PropTypes.bool.isRequired,
};

export default InlineCopyLinkAndGifButton;
