import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { getFlatDataFromTree, getTreeFromFlatData,map } from 'react-sortable-tree';

import { PrivacyStatus } from 'types/interfaces';

import FolderContext from 'context/FolderContext';
import { UserContext } from 'context/UserContext';
import { canMoveFolder,getFolderPath } from 'utils/folderUtils';

const getNodeKey = ({ node }) => node.id;

export default function useTreeData({ isTeamLibrary }) {
  const [treeData, setTreeData] = useState([]);
  const { folder, folders } = useContext(FolderContext);
  const { hasUnifiedSeatExperience } = useContext(UserContext);

  useEffect(() => {
    const privacyStatus = isTeamLibrary ? PrivacyStatus.Team : PrivacyStatus.Individual;

    const flatData = folders.filter((folder) => folder.privacyStatus === privacyStatus);

    setTreeData((treeData) => {
      // Turn the flat `folders` list into a tree.
      const nextTreeData = getTreeFromFlatData({
        flatData,
        getKey: (node) => node.id,
        getParentKey: (node) => node.parentFolder,
        rootKey: null,
      });

      // Flatten the previous tree for easy look-ups.
      const flatTree = getFlatDataFromTree({
        treeData,
        ignoreCollapsed: false,
        getNodeKey: ({ node }) => node.id,
      });

      // It's also useful to have the path up to the currently selected node.
      // @ts-ignore
      const ancestorPath = getFolderPath(folder, folders).slice(0, -1);

      // Map over the tree and make sure the appropriate nodes are expanded.
      return map({
        treeData: nextTreeData,
        getNodeKey,
        ignoreCollapsed: false,
        callback: ({ node }) => {
          const prevItem = flatTree.find((item) => item.node.id === node.id);
          const prevNode = prevItem && prevItem.node;

          // Don't collapse a node just because another one changed.
          const previouslyExpanded = Boolean(prevNode?.expanded);

          // Auto-expand a node to reveal any new children.
          let prevChildrenIds: number[] | null = [];

          if (!prevNode) {
            prevChildrenIds = null;
          } else if (prevNode.children) {
            prevChildrenIds = prevNode.children.map((node) => node.id);
          }

          const hasNewChildren =
            prevChildrenIds && node.children?.some(({ id }) => !prevChildrenIds!.includes(id));

          // Make sure the tree is expanded up to the currently selected node.
          // This is really only relevant when navigating directly to a nested
          // folder on page load.
          const isAncestorOfCurrentFolder = ancestorPath.includes(node.id);

          // Root nodes should be initially expanded. After that they should
          // stay collapsed when collapsed.
          // This behavior is overruled by the unified app, where we need to
          // conserve space in the subnav for other items on initial load.
          const isFirstRenderOfRootNode =
            !prevItem && node.parentFolder === null && !hasUnifiedSeatExperience;

          const expanded =
            isAncestorOfCurrentFolder ||
            previouslyExpanded ||
            hasNewChildren ||
            isFirstRenderOfRootNode;

          return { ...node, expanded };
        },
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder, folders, isTeamLibrary]);

  const handleChange = (nextTreeData) => {
    setTreeData(nextTreeData);
  };

  const canDropFolderNode = React.useCallback(
    // @ts-ignore
    ({ node, nextParent }) => canMoveFolder(node, nextParent, folders),
    [folders],
  );

  const expandNode = (item) =>
    setTreeData((treeData) =>
      map({
        treeData,
        getNodeKey,
        ignoreCollapsed: false,
        callback: ({ node }) => ({
          ...node,
          expanded: node.id === item.node.id ? true : node.expanded,
        }),
      }),
    );

  return {
    treeData,
    handleChange,
    expandNode,
    canDropFolderNode,
  };
}
