import React from 'react';

import PropTypes from 'prop-types';

import { Div } from '@driftt/tide-core';

import InlineCloneButton from './InlineCloneButton';
import InlineCopyLinkAndGIFButton from './InlineCopyLinkAndGIFButton';
import InlineVideoActionDropdown from './InlineVideoActionDropdown';

function VideoActions({
  isUserUploadedVideo,
  publicUrl,
  onDeleteVideo,
  shareUrl,
  thumbnailUrl,
  videoId,
  videoTitle,
  isTeammateVideo,
  onEditTitleClicked,
}) {
  return (
    <>
      <Div onClick={(e) => e.stopPropagation()}>
        <InlineCopyLinkAndGIFButton
          publicUrl={publicUrl}
          thumbnailUrl={thumbnailUrl}
          videoTitle={videoTitle}
          isTeammateVideo={isTeammateVideo}
        />
      </Div>
      {!isTeammateVideo ? (
        <Div onClick={(e) => e.stopPropagation()}>
          <InlineVideoActionDropdown
            isUserUploadedVideo={isUserUploadedVideo}
            publicUrl={publicUrl}
            shareUrl={shareUrl}
            onDeleteVideo={onDeleteVideo}
            videoId={videoId}
            videoTitle={videoTitle}
            onEditTitleClicked={onEditTitleClicked}
          />
        </Div>
      ) : (
        <Div onClick={(e) => e.stopPropagation()}>
          <InlineCloneButton videoId={videoId} />
        </Div>
      )}
    </>
  );
}

VideoActions.propTypes = {
  isUserUploadedVideo: PropTypes.bool.isRequired,
  publicUrl: PropTypes.string.isRequired,
  onDeleteVideo: PropTypes.func.isRequired,
  shareUrl: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
  videoId: PropTypes.number.isRequired,
  videoTitle: PropTypes.string.isRequired,
  isTeammateVideo: PropTypes.bool.isRequired,
  onEditTitleClicked: PropTypes.func.isRequired,
};

export default VideoActions;
