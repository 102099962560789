import * as React from 'react';

import { Button } from '@driftt/tide-core';

import './style.css';

interface VideoPageBannerProps {
  backButtonText: string;
  onBackClick: () => void;
  description: string;
}

const VideoPageBanner = ({ backButtonText, onBackClick, description }: VideoPageBannerProps) => {
  return (
    <div className="viewing-own-video-header">
      <Button
        className="back-to-share-button"
        type="utility"
        icon="arrow-2-left"
        onClick={onBackClick}
      >
        {backButtonText}
      </Button>
      <div className="own-video-helper-text">{description}</div>
    </div>
  );
};

export default VideoPageBanner;
