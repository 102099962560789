import React, { useContext } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { A, Icon } from '@driftt/tide-core';

import { segmentTrack } from 'utils/metrics';

import { RoutingContext } from '../util';

const EndNode = ({
  label,
  path,
  icon,
  className,
  onClick,
  exact = true,
  external = typeof path === 'string' && path.match(/^http/),
  disabled,
  ...rest // captures native html attributes
}) => {
  const NavLink = useContext(RoutingContext);
  const NavLinkProps = {
    exact: exact,
    to: path,
    activeClassName: 'tc-subnav-link--active',
  };
  const AnchorTagProps = {
    href: path,
  };

  const trackedOnClick = () => {
    segmentTrack('Video Subnav Item Clicked', { path });
    return onClick && onClick();
  };

  const Link = !external && NavLink ? NavLink : A;
  return (
    <Link
      className={classnames('tc-subnav-link', { 'tc-subnav-link--disabled': disabled }, className)}
      onClick={() => trackedOnClick()}
      {...(external ? AnchorTagProps : NavLinkProps)}
      {...rest}
    >
      {icon && <Icon name={icon} height="12" width="12" />}
      {label}
    </Link>
  );
};

EndNode.propTypes = {
  label: PropTypes.node.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  exact: PropTypes.bool,
  external: PropTypes.bool,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default EndNode;
