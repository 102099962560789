import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { IconButton, ToolTip } from '@driftt/tide-core';

import { getVideoDownloadUrls } from 'api';

function InlineCopyGIFButton({ videoId }) {
  const [justCopied, setJustCopied] = useState(false);

  const handleClick = async (e) => {
    if (justCopied) {
      return;
    }

    const data = await getVideoDownloadUrls({ videoId });

    const onCopy = (e) => {
      const html = [
        '<img src="' + data.url + '">',
        // Add the src as hidden text for apps
        // (like Slack) that don't support pasting GIFs.
        '<div style="display: none" tabIndex="-1" aria-hidden="true">',
        data.url,
        '</div>',
        // Strangely, this trailing space is required for pasting into Slack.
        ' ',
      ].join('');

      e.clipboardData.setData('text/html', html);
      e.clipboardData.setData('text/plain', data.url);
      e.preventDefault();
    };

    document.addEventListener('copy', onCopy);
    document.execCommand('copy');
    document.removeEventListener('copy', onCopy);

    setJustCopied(true);
    setTimeout(() => setJustCopied(false), 3000);
  };

  return (
    <ToolTip flow="up" render={() => (justCopied ? 'Copied!' : 'Copy GIF')}>
      <IconButton
        className="inline-copy-gif-button"
        icon={justCopied ? 'check' : 'link-1'}
        size="large"
        onClick={handleClick}
      ></IconButton>
    </ToolTip>
  );
}

InlineCopyGIFButton.propTypes = {
  videoId: PropTypes.number.isRequired,
};

export default InlineCopyGIFButton;
