import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { ActionDropdown, IconButton, ToolTip } from '@driftt/tide-core';

import DeleteFolderModal from 'components/libraryNavigationHeader/DeleteFolderModal';
import RenameFolderModal from 'components/libraryNavigationHeader/RenameFolderModal';
import ModalPortal from 'components/modalPortal';
import { UserContext } from 'context/UserContext';

import './FolderActions.css';

const WarningToolTip = ({ label, warning, showWarning, ...otherProps }) => {
  return showWarning ? (
    <ToolTip {...otherProps} render={() => warning}>
      {label}
    </ToolTip>
  ) : (
    label
  );
};

WarningToolTip.propTypes = {
  label: PropTypes.node,
  warning: PropTypes.string,
  showWarning: PropTypes.bool,
}

function FolderActions({ folder }) {
  const { user } = useContext(UserContext);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const hasOwnership = folder.owner === user.id;

  return (
    <div onClick={(e) => e.stopPropagation()} style={{ float: 'right' }}>
      <ActionDropdown
        className="folder-actions"
        trigger={
          <ToolTip flow="up" render={() => 'Options'}>
            <IconButton className="folder-actions__dropdown-button" icon="meatballs" size="large" />
          </ToolTip>
        }
        align="right"
        actions={[
          {
            label: (
              <WarningToolTip
                label="Rename folder"
                warning="You don’t have permission to rename this folder"
                showWarning={!hasOwnership}
                flow="left"
              />
            ),
            action: (hideDropdown) => {
              setShowRenameModal(true);
              hideDropdown();
            },
            disabled: !hasOwnership,
          },
          {
            label: (
              <WarningToolTip
                label="Delete folder"
                warning="You don’t have permission to delete this folder"
                showWarning={!hasOwnership}
                flow="left"
              />
            ),
            action: (hideDropdown) => {
              setShowDeleteModal(true);
              hideDropdown();
            },
            disabled: !hasOwnership,
          },
        ]}
      />
      <ModalPortal>
        <RenameFolderModal
          folderId={folder.id}
          folderTitle={folder.title}
          open={showRenameModal}
          onClose={() => setShowRenameModal(false)}
        />
        <DeleteFolderModal
          folderId={folder.id}
          parentFolder={folder.parentFolder}
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        />
      </ModalPortal>
    </div>
  );
}

FolderActions.propTypes = {
  folder: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    parentFolder: PropTypes.number.isRequired,
    owner: PropTypes.string,
  }).isRequired,
};

export default FolderActions;
