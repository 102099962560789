/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Div, H3, Icon } from '@driftt/tide-core';

import { getChildNodes, RoutingContext } from './util';

class Subnav extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    open: PropTypes.bool,
    nodes: PropTypes.array.isRequired,
    NavLink: PropTypes.object,
    mode: PropTypes.oneOf(['overlay', 'slide']),
    customElement: PropTypes.node,
    onToggle: PropTypes.func,
  };

  static defaultProps = {
    mode: 'slide',
    open: true,
    customElement: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
    };

    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    const { onToggle } = this.props;

    // eslint-disable-next-line no-unused-expressions
    onToggle && onToggle();
    this.setState({ open: !this.state.open });
  }

  render() {
    const { open } = this.state;
    const { className, mode, NavLink = null } = this.props;

    const modeClassName = 'tc-subnav--' + mode;
    const nodes = getChildNodes(this.props.nodes);

    return (
      <Div className={classnames('tc-subnav-wrapper', { 'tc-subnav-wrapper--collapsed': !open })}>
        <Div
          className={classnames(
            'tc-subnav',
            { 'tc-subnav--collapsed': !open },
            modeClassName,
            className,
          )}
        >
          <H3 className="tc-subnav-header">{this.props.title}</H3>
          <Div className="tc-subnav-content">
            <RoutingContext.Provider value={NavLink}>
              {this.props.customElement}
              {nodes}
            </RoutingContext.Provider>
          </Div>
        </Div>
        <Div
          className={classnames('tc-subnav-toggle-button', {
            'tc-subnav-toggle-button--collapsed': !open,
          })}
          onClick={this.onToggle}
        >
          <Icon name="arrow-1-left" width="8" height="8" />
        </Div>
      </Div>
    );
  }
}

export default Subnav;
