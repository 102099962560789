import React from 'react';

import folderSvg from 'assets/svg/folder-thumbnail.svg';
import PropTypes from 'prop-types';

function FolderThumbnailAndTitle({ title, users, folderOwner, isTeamResult }) {
  const owner = users.find((user) => user.id === folderOwner);
  const ownerName =
    owner && ([owner.firstName, owner.lastName].filter(Boolean).join(' ') || owner.email);

  return (
    <div className="video-library-row__thumbnail-and-title">
      <img alt="folder" src={folderSvg} />
      <div className="video-library-row__title-and-creator row-title-flex">
        <div className="video-library-row__title">{title}</div>
        {isTeamResult && ownerName && <div className="video-library-row__creator">{ownerName}</div>}
      </div>
    </div>
  );
}

FolderThumbnailAndTitle.propTypes = {
  title: PropTypes.node,
  users: PropTypes.array,
  folderOwner: PropTypes.number,
  isTeamResult: PropTypes.bool,
};

export default FolderThumbnailAndTitle;
