import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { ActionDropdown, IconButton, ToolTip } from '@driftt/tide-core';

import { deleteVideo } from 'api';
import ConfirmDeleteModal from 'components/confirmDeleteModal';
import FullPageSpinner from 'components/fullPageSpinner';
import FolderContext from 'context/FolderContext';
import { downloadVideo } from 'utils';

import AddToFolderModal from './AddToFolderModal';

import './InlineGifActionDropdown.css';

const InlineGifActionDropdown = ({ onDeleteVideo, videoId, onEditTitleClicked }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddToFolderModal, setShowAddToFolderModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { folders, onMoveVideoToFolder } = useContext(FolderContext);

  const handleConfirmDelete = async (videoId) => {
    setShowSpinner(true);
    try {
      const response = deleteVideo({ videoId });
      if (response) {
        onDeleteVideo(true);
      }
    } catch (err) {
      Error(err);
    } finally {
      setShowSpinner(false);
      setShowDeleteModal(false);
    }
  };

  const handleConfirmAddToFolder = (folderId) => {
    onMoveVideoToFolder({ folderId, videoId });
    setShowAddToFolderModal(false);
  };

  return (
    <>
      <ActionDropdown
        className="inline-gif-action-dropdown"
        trigger={
          <ToolTip flow="up" render={() => 'More options'}>
            <IconButton
              className="inline-gif-action-dropdown-button"
              icon="meatballs"
              size="large"
            />
          </ToolTip>
        }
        align="right"
        actions={[
          {
            label: 'Move to folder...',
            action: (hideDropdown) => {
              hideDropdown();
              setShowAddToFolderModal(true);
            },
            disabled: !folders || !folders.length,
          },
          {
            label: 'Rename GIF',
            action: (hideDropdown) => {
              hideDropdown();
              onEditTitleClicked(videoId);
            },
          },
          {
            label: 'Download GIF',
            action: (hideDropdown) => {
              hideDropdown();
              downloadVideo(videoId);
            },
          },
          {
            label: 'Delete GIF',
            action: (hideDropdown) => {
              hideDropdown();
              setShowDeleteModal(true);
            },
          },
        ].filter(Boolean)}
      />
      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        onClickConfirm={() => handleConfirmDelete(videoId)}
        onClickCancel={() => setShowDeleteModal(false)}
        target="GIF"
      />
      <AddToFolderModal
        isOpen={showAddToFolderModal}
        onClickConfirm={handleConfirmAddToFolder}
        onClickCancel={() => setShowAddToFolderModal(false)}
      />
      <FullPageSpinner show={showSpinner} />
    </>
  );
};

InlineGifActionDropdown.propTypes = {
  onDeleteVideo: PropTypes.func.isRequired,
  videoId: PropTypes.number.isRequired,
  onEditTitleClicked: PropTypes.func.isRequired,
};

export default InlineGifActionDropdown;
