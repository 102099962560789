import React from 'react';
import { useContext } from 'react';

import { UserContext } from 'context/UserContext';

import FolderNavTree from './FolderNavTree';

import './styles.css';

function FoldersSubnav() {
  const { userHasFeature } = useContext(UserContext);
  const hasTeamLibrary = userHasFeature('HAS_TEAM_LIBRARY');

  return (
    <div className="folders-subnav">
      <div className="folders-subnav__tree-container">
        <FolderNavTree isTeamLibrary={false} />
      </div>
      {hasTeamLibrary && (
        <div className="folders-subnav__tree-container">
          <FolderNavTree isTeamLibrary={true} />
        </div>
      )}
    </div>
  );
}

export default FoldersSubnav;
