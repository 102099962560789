import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { InlineEditInput } from '@driftt/tide-core';

import { updateVideoTitle } from 'api';

import './VideoTitle.css';

function VideoTitle({ initialValue, videoId }) {
  const [value, setValue] = useState(initialValue);

  const handleSubmit = async (nextValue) => {
    try {
      await updateVideoTitle({ videoId, value: nextValue });
      setValue(nextValue);
      document.title = `Drift | ${nextValue}`;
    } catch (e) {
      Error(e);
    }
  };

  return (
    <div className="video-title-container">
      <InlineEditInput
        type="heading"
        headingSize="h3"
        value={value}
        onSubmit={handleSubmit}
        withSave
      />
    </div>
  );
}

VideoTitle.propTypes = {
  initialValue: PropTypes.string.isRequired,
  videoId: PropTypes.number.isRequired,
};

export default VideoTitle;
