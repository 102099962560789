import React from 'react';

import PropTypes from 'prop-types';

import { Div } from '@driftt/tide-core';

import InlineCloneButton from './InlineCloneButton';
import InlineCopyGIFButton from './InlineCopyGIFButton';
import InlineGifActionDropdown from './InlineGifActionDropdown';

function GifActions({ onDeleteVideo, videoId, isTeammateVideo, onEditTitleClicked }) {
  return (
    <>
      <Div onClick={(e) => e.stopPropagation()}>
        <InlineCopyGIFButton videoId={videoId} />
      </Div>
      {!isTeammateVideo ? (
        <Div onClick={(e) => e.stopPropagation()}>
          <InlineGifActionDropdown
            onDeleteVideo={onDeleteVideo}
            videoId={videoId}
            onEditTitleClicked={onEditTitleClicked}
          />
        </Div>
      ) : (
        <Div onClick={(e) => e.stopPropagation()}>
          <InlineCloneButton videoId={videoId} />
        </Div>
      )}
    </>
  );
}

GifActions.propTypes = {
  onDeleteVideo: PropTypes.func.isRequired,
  videoId: PropTypes.number.isRequired,
  isTeammateVideo: PropTypes.bool.isRequired,
  onEditTitleClicked: PropTypes.func.isRequired,
};

export default GifActions;
