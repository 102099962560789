import qs from 'query-string';

import { isRootPersonalLibraryFolder, isRootTeamLibraryFolder } from 'utils/folderUtils';

// Given a `folder`, returns a new function that can be used to by a
// react-router NavLink component to determine if it's active.
const isNavTreeLinkActive = (folder) => (match, location) => {
  const { source_type, folder_id } = qs.parse(location.search);

  if (!match || source_type) {
    return false;
  }

  if (folder_id) {
    return match && folder_id === folder?.id?.toString();
  }

  if (isRootPersonalLibraryFolder(folder)) {
    return match.url === '/video/';
  }

  if (isRootTeamLibraryFolder(folder)) {
    return match.url === '/video/team/';
  }

  return false;
};

export default isNavTreeLinkActive;
