import defaultConfig from './default'
import euConfig from './eu.json'
import qaConfig from './qa.json'
import usConfig from './us.json'

declare global {
  interface Window {
    DRIFT_CONFIG: any
    DRIFT_REGION: string
    DRIFT_USSR: boolean
    __ENV__: string
  }
}

const localConfigs: any = {
  QA: qaConfig,
  EU: euConfig,
  US: usConfig
}

export const isEU = window.DRIFT_REGION === 'EU'

export default {
  ...(window.DRIFT_CONFIG || {}),
  ...defaultConfig,
  ...localConfigs[window.DRIFT_REGION || (window.__ENV__ === 'PRODUCTION' ? 'US' : 'QA')]
}
