import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { InlineEditInput } from '@driftt/tide-core';

import { updateVideoTitle } from 'api';
import { SOURCE_TYPE_GIF, SOURCE_TYPE_MEETING_RECORDING } from 'utils/constants';

function SourceTypeBadge({ sourceType }) {
  if (sourceType === SOURCE_TYPE_GIF) {
    return <div className="video-library-row__source-type">GIF</div>;
  }

  if (sourceType === SOURCE_TYPE_MEETING_RECORDING) {
    return <div className="video-library-row__source-type">Zoom</div>;
  }

  return null;
}

SourceTypeBadge.propTypes = {
  sourceType: PropTypes.string,
}

function RecordingThumbnailAndTitle({
  isTeamResult,
  thumbnailDefault,
  thumbnailUrl,
  users,
  video,
  videoTitle,
  videoUser,
  videoSourceType,
  isEditingTitle,
  isEditingTitleCallback,
}) {
  const owner = users.find((user) => user.id === videoUser);
  const ownerName =
    owner && ([owner.firstName, owner.lastName].filter(Boolean).join(' ') || owner.email);
  const [title, setTitle] = useState(videoTitle);

  const handleSubmit = async (nextValue) => {
    const videoId = video.id;
    try {
      await updateVideoTitle({ videoId, value: nextValue });
      setTitle(nextValue);
      isEditingTitleCallback();
    } catch (e) {
      Error(e);
    }
  };

  const titleEditor = () => {
    if (isEditingTitle) {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <InlineEditInput
            className="video-library-row-edit-title"
            type="text"
            value={title}
            onSubmit={handleSubmit}
            isEditing={true}
            onCancel={() => {
              isEditingTitleCallback();
            }}
            shouldValidate={true}
            withSave
          />
        </div>
      );
    } else {
      return <div className="video-library-row__title">{title}</div>;
    }
  };

  return (
    <div className="video-library-row__thumbnail-and-title">
      <div className="video-library-row__thumbnail">
        <img src={thumbnailUrl || thumbnailDefault} alt="thumbnail" />
        <SourceTypeBadge sourceType={videoSourceType} />
      </div>
      <div className="video-library-row__title-and-creator row-title-flex">
        {titleEditor()}
        {isTeamResult && ownerName && <div className="video-library-row__creator">{ownerName}</div>}
      </div>
    </div>
  );
}

RecordingThumbnailAndTitle.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number,
  }),
  isTeamResult: PropTypes.bool.isRequired,
  thumbnailDefault: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ).isRequired,
  videoTitle: PropTypes.string.isRequired,
  videoUser: PropTypes.number.isRequired,
  videoSourceType: PropTypes.number,
  isEditingTitle: PropTypes.bool,
  isEditingTitleCallback: PropTypes.func,
};

export default RecordingThumbnailAndTitle;
