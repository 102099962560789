import React from 'react';

import * as Sentry from '@sentry/browser';
import cx from 'classnames';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import offset from 'videojs-offset';

import { Button, Footer, Modal, P } from '@driftt/tide-core';

import { checkTranscodeStatus, createTranscode, fetchVideoData } from 'api';
import InteractiveCaptions from 'components/interactiveCaptions';
import addHooksToVideoPlayer from 'layouts/sharePage/shareVideo/VideoEditor/addHooksToVideoPlayer.js';
import { shouldShowCaptions } from 'utils/playerUtils';
import {
  generateOptions,
  isPartiallyTranscodedVideo,
  MEDIA_ERR_NETWORK,
  MEDIA_ERR_SRC_NOT_SUPPORTED,
  prioritizeMP4,
  replacePlayerWithNewSource,
  VIDEO_SOURCE_ERROR_MAX_RETRY,
} from 'utils/videoPlayerUtils';

import 'videojs-hotkeys/videojs.hotkeys';

import './styles.css';

videojs.registerPlugin('offsetVideo', offset);

// this is so that the newrelic agent can pick up on these events
window.videojs = videojs;

class VideoPlayer extends React.Component {
  videoNode = null;
  transcodeTimer = null;
  mediaErrorCount = 0;
  constructor(props) {
    super(props);
    this.state = {
      isPartiallyTranscodedVideo: false,
      transcodingLoading: false,
      videoConfig: {},
      videoLoadErrored: false,
    };
  }
  componentDidMount() {
    const {
      videoData = {},
      isMobile = false,
      playerOptions = {},
      isEmbeddedPlayer = false,
      userCustomizations = {},
    } = this.props;
    const { videoSources = [], thumbnailUrl } = videoData;
    const parsedSources = isMobile ? prioritizeMP4(videoSources) : videoSources;

    videoData['has_captions'] = shouldShowCaptions(userCustomizations, playerOptions);

    this.setState(
      {
        videoConfig: {
          ...generateOptions(isMobile),
          sources: parsedSources,
          poster: thumbnailUrl,
          muted: !videoData.isAudioIncluded, // takes care of the muted icon
        },
      },
      () => {
        this.player = videojs(this.videoNode, this.state.videoConfig, () => {
          this.setState(
            {
              isPartiallyTranscodedVideo: isPartiallyTranscodedVideo(this.player),
            },
            () => {
              if (this.state.isPartiallyTranscodedVideo) {
                this.player.one('play', () => {
                  if (this.state.transcodingLoading) {
                    return; // transcoding is already in progress
                  }

                  createTranscode(videoData.videoId, window.jwt).then(() =>
                    this.setState({ transcodingLoading: true }),
                  );
                  this.transcodeTimer = setInterval(() => {
                    checkTranscodeStatus(videoData.videoId, window.jwt).then((data) => {
                      if (data.job_completed) {
                        this.setState({ transcodingLoading: false });
                        const newSrc = {
                          type: 'video/mp4',
                          src: data.screen_full_transcode_url || data.facecam_full_transcode_url,
                        };
                        replacePlayerWithNewSource(this.player, newSrc);
                        clearInterval(this.transcodeTimer);
                      }
                    });
                  }, 15 * 1000);
                });
              }
            },
          );
        });
        const duration = this.player.duration.bind(this.player);

        this.player.duration = (seconds) => {
          if (videoData.startTime != null && videoData.endTime != null) {
            return videoData.endTime - videoData.startTime;
          } else {
            return duration(seconds);
          }
        };
        this.player.on('error', (e) => {
          const playerError = this.player.error();
          Sentry.captureMessage(playerError.message, 'error');
          if (
            [MEDIA_ERR_NETWORK, MEDIA_ERR_SRC_NOT_SUPPORTED].includes(playerError.code) &&
            this.mediaErrorCount <= VIDEO_SOURCE_ERROR_MAX_RETRY
          ) {
            this.mediaErrorCount++;
            fetchVideoData(window.videoId, window.jwt)
              .then((videoData) => {
                replacePlayerWithNewSource(this.player, videoData.videoSources);
              })
              .catch((e) => {
                this.setState({ videoLoadErrored: true });
              });
          } else {
            this.setState({ videoLoadErrored: true });
          }
        });

        // takes care of setting the audio slider to 0
        if (!videoData.isAudioIncluded) {
          this.player.volume(0);
        }

        // eslint-disable-next-line no-unused-expressions
        window.nrvideo?.Core?.addTracker(new window.nrvideo.VideojsTracker(this.player));
        const onCaptionsLoaded = () => {
          const tracks = this.player.textTracks();
          let validTracks = [];
          for (let i = 0; i < tracks.length; i++) {
            const track = tracks[i];
            if (track.kind === 'captions' || track.kind === 'subtitles') {
              validTracks.push(track);
            }
          }
          this.setState({ textTracks: validTracks });
        };
        addHooksToVideoPlayer({
          player: this.player,
          videoData,
          playerOptions,
          isMobile,
          isEmbeddedPlayer,
          onCaptionsLoaded,
        });
      },
    );
  }

  // destroy videojs player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    const { isMobile, isEmbeddedPlayer = false } = this.props;
    const showTranscripts = new URLSearchParams(window.location.search).get('showTranscripts');

    return (
      <div
        data-vjs-player
        className={cx('video-container', { 'embedded-player': isEmbeddedPlayer })}
      >
        <Modal
          title="Something went wrong!"
          open={this.state.videoLoadErrored}
          onClose={() => this.setState({ videoLoadErrored: false })}
          size="small"
          type="warning"
        >
          <P>
            Looks like we ran into some trouble loading your video. Refresh the page to try again.
          </P>
          <Footer>
            <Button
              type="secondary"
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </Button>
          </Footer>
        </Modal>

        <div
          className={cx('video-container-inner', {
            mobile: isMobile,
            full: !showTranscripts,
            'with-captions': showTranscripts,
          })}
        >
          <video
            ref={(node) => (this.videoNode = node)}
            className="video-js vjs-big-play-centered"
            playsInline
          ></video>
          {isEmbeddedPlayer && showTranscripts && this.state.textTracks && (
            <InteractiveCaptions
              videoTracks={this.state.textTracks}
              selectionDelegate={(selection) => {
                this.player.currentTime(selection);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

VideoPlayer.propTypes = {
  videoData: PropTypes.object,
  isMobile: PropTypes.bool,
  playerOptions: PropTypes.object,
  isEmbeddedPlayer: PropTypes.bool,
  userCustomizations: PropTypes.object,
}

export default VideoPlayer;
