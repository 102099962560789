import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Div, P, Table } from '@driftt/tide-core';

import { UserContext } from 'context/UserContext';
import { formatTime, generateShareUrl } from 'utils';
import { SOURCE_TYPE_GIF } from 'utils/constants';
import { getFolderRelativeUrl } from 'utils/folderUtils';

import Actions from './Actions';
import CloningUsers from './CloningUsers';
import FolderActions from './FolderActions';
import FolderRowDragWrapper from './FolderRowDragWrapper';
import FolderThumbnailAndTitle from './FolderThumbnailAndTitle';
import RecordingThumbnailAndTitle from './RecordingThumbnailAndTitle';
import VideoRowDragWrapper from './VideoRowDragWrapper';

import './VideoLibraryTable.css';

const isGif = (video) => video.sourceType === SOURCE_TYPE_GIF;

const VideoLibraryTable = ({
  loading,
  isTeamResult,
  videos,
  folders,
  users,
  sortKey,
  sortDirection,
  setSortKey,
  setSortDirection,
  onDeleteVideo,
  sourceType,
  isReadOnlyFolder,
  isHowToFolder,
}) => {
  const history = useHistory();
  const { user, userHasFeature } = useContext(UserContext);
  const [videoBeingEditedId, setVideoBeingEditedId] = useState(null);
  const hasNewHowToPage = userHasFeature('HAS_NEW_HOW_TO_PAGE');

  const handleTableHeaderClicked = (columnSortKey) => {
    const newSortDirection =
      sortKey === columnSortKey ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'desc';
    setSortKey(columnSortKey);
    setSortDirection(newSortDirection);
  };

  const folderComparator = (a, b) => {
    if (sortKey !== 'title') {
      return 0;
    }

    const d = sortDirection === 'desc' ? -1 : 1;
    return a.title.toLowerCase() < b.title.toLowerCase() ? -d : d;
  };

  const columns = [
    {
      label: 'Name',
      width: '38%',
      sortable: true,
      sorting: sortKey === 'title',
      direction: sortDirection,
      onClick: () => handleTableHeaderClicked('title'),
      getContent: (video) => (
        <RecordingThumbnailAndTitle
          isTeamResult={isTeamResult}
          users={users}
          video={video}
          videoTitle={video.title}
          videoUser={video.user}
          videoSourceType={video.sourceType}
          thumbnailUrl={video.thumbnailUrl}
          thumbnailDefault={video.thumbnailDefault}
          isEditingTitle={video.id === videoBeingEditedId}
          isEditingTitleCallback={() => {
            setVideoBeingEditedId(null);
          }}
        />
      ),
      getFolderContent: (folder) => (
        <FolderThumbnailAndTitle
          title={folder.title}
          users={users}
          folderOwner={folder.owner}
          isTeamResult={isTeamResult}
        />
      ),
    },
    {
      label: 'Duration',
      width: '8%',
      sortable: true,
      sorting: sortKey === 'duration',
      direction: sortDirection,
      onClick: () => handleTableHeaderClicked('duration'),
      getContent: (video) =>
        isGif(video) ? 'GIF' : video.duration ? formatTime(video.duration) : '-',
    },
    {
      label: 'Created',
      width: '12%',
      sortable: true,
      sorting: sortKey === 'datetime_created',
      direction: sortDirection,
      onClick: () => handleTableHeaderClicked('datetime_created'),
      getContent: (video) => video.displayDate,
    },
    isTeamResult
      ? {
          label: 'Clones created',
          width: '12%',
          sortable: true,
          sorting: sortKey === 'num_clones',
          direction: sortDirection,
          onClick: () => handleTableHeaderClicked('num_clones'),
          getContent: (video) => video.numClones,
        }
      : null,
    isTeamResult
      ? {
          label: 'Cloned by',
          width: '22%',
          sortable: true,
          sorting: sortKey === 'num_cloned_by',
          direction: sortDirection,
          onClick: () => handleTableHeaderClicked('num_cloned_by'),
          getContent: (video) => (
            <Div className="video-library-row__duration-viewed-plus-actions">
              <CloningUsers
                allUsers={users}
                cloningUsers={video.cloners}
                isTeammateVideo={user.id !== video.user}
              />
              <Actions
                video={video}
                onDeleteVideo={onDeleteVideo}
                onEditTitleClicked={(videoId) => {
                  setVideoBeingEditedId(videoId);
                }}
              />
            </Div>
          ),
          getFolderContent: (folder) => <FolderActions folder={folder} />,
        }
      : null,
    !isTeamResult
      ? {
          label: 'Views',
          width: '8%',
          sortable: true,
          sorting: sortKey === 'view_count',
          direction: sortDirection,
          onClick: () => handleTableHeaderClicked('view_count'),
          getContent: (video) => (isGif(video) ? 'N/A' : video.viewCount),
        }
      : null,
    !isTeamResult && !isReadOnlyFolder
      ? {
          label: 'Avg % viewed',
          width: '9%',
          sortable: true,
          sorting: sortKey === 'average_percent_viewed',
          direction: sortDirection,
          onClick: () => handleTableHeaderClicked('average_percent_viewed'),
          getContent: (video) =>
            isGif(video)
              ? 'N/A'
              : video.averagePercentViewed !== null
              ? video.averagePercentViewed + '%'
              : '-',
        }
      : null,
    !isTeamResult && !isReadOnlyFolder
      ? {
          label: 'Avg duration viewed',
          width: '15%',
          sortable: false,
          getContent: (video) => (
            <Div className="video-library-row__duration-viewed-plus-actions">
              <P className="video-library-row__duration_viewed">
                {isGif(video)
                  ? 'N/A'
                  : video.averagePercentViewed !== null && video.duration
                  ? formatTime(video.averagePercentViewed * video.duration * 0.01)
                  : '-'}
              </P>
              <Actions
                video={video}
                onDeleteVideo={onDeleteVideo}
                onEditTitleClicked={(videoId) => {
                  setVideoBeingEditedId(videoId);
                }}
              />
            </Div>
          ),
          getFolderContent: (folder) => <FolderActions folder={folder} />,
        }
      : null,
  ].filter(Boolean);

  const getShareUrl = (video) => {
    let url = new URL(generateShareUrl(video.shareUrl));

    if (window.location.host.indexOf('impersonation') !== -1) {
      url.host = window.location.host;
      url.port = window.location.port;
    }

    return url;
  };

  const getVideoUrl = ({ userId, video }) => {
    if (
      userId === video.user ||
      (hasNewHowToPage && window.location.pathname.indexOf('/how-to/') !== -1)
    ) {
      return getShareUrl(video);
    } else {
      return video.publicUrl;
    }
  };

  const maybeMakeDraggable = (data) => {
    if (isReadOnlyFolder) {
      return data;
    }

    // HACK: The Tide Table does not expose a direct way to wrap its <tr>
    // elements. This hack exploits a quirk of JavaScript and a detail of the
    // Table implementation to get around that. `data` is usually provided to
    // to the Table as an array but in reality (due to JavaScript's duck
    // typing) any object with a `map` method will work. Here we're returning a
    // custom mappable object that takes the result of the Table mapping over
    // `data` (i.e. an array of <tr> elements) and then applies an additional
    // transformation. Note that this violates encapsulation and will break
    // when Table is rewritten to process `data` with a different array method
    // or maps it to something other than <tr> elements.
    return {
      map: (...args) =>
        data.map(...args).map((trElement, i) => {
          const { key } = trElement;
          const folder = folders[i];
          const video = videos[i - folders.length];

          return video ? (
            <VideoRowDragWrapper key={key} videoId={video.id}>
              {trElement}
            </VideoRowDragWrapper>
          ) : (
            <FolderRowDragWrapper key={key} folder={folder}>
              {trElement}
            </FolderRowDragWrapper>
          );
        }),
    };
  };
  return (
    <Table
      className="video-library-table"
      isWorking={loading}
      onRowClick={({ videoUrl, videoId, folder, isOwnVideo }, event) => {
        if (videoUrl) {
          isOwnVideo || isHowToFolder ? history.push(videoUrl.pathname) : window.open(videoUrl);
        } else {
          history.push(getFolderRelativeUrl(folder));
        }
      }}
      columns={columns}
      data={maybeMakeDraggable([
        ...folders.sort(folderComparator).map((folder) => ({
          rowClickEventData: { folder },
          key: 'folder-' + folder.id,
          data: columns.map((column) => ({
            content: column.getFolderContent ? column.getFolderContent(folder) : null,
          })),
        })),
        ...videos.map((video) => ({
          rowClickEventData: {
            videoUrl: getVideoUrl({ userId: user.id, video }),
            videoId: video.id,
            isOwnVideo: user.id === video.user,
          },
          key: 'video-' + video.id,
          data: columns.map((column) => ({ content: column.getContent(video) })),
        })),
      ])}
    />
  );
};

VideoLibraryTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  isTeamResult: PropTypes.bool.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      averagePercentViewed: PropTypes.number,
      displayDate: PropTypes.string.isRequired,
      duration: PropTypes.number,
      id: PropTypes.number.isRequired,
      publicUrl: PropTypes.string.isRequired,
      shareUrl: PropTypes.string.isRequired,
      thumbnailDefault: PropTypes.string.isRequired,
      thumbnailGifUrlForCopyLink: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      title: PropTypes.string.isRequired,
      viewCount: PropTypes.number.isRequired,
      extraData: PropTypes.string.isRequired,
      sourceType: PropTypes.number.isRequired,
      user: PropTypes.number,
    }),
  ),
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  sortKey: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  setSortKey: PropTypes.func.isRequired,
  setSortDirection: PropTypes.func.isRequired,
  onDeleteVideo: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ).isRequired,
  sourceType: PropTypes.number,
  isReadOnlyFolder: PropTypes.bool,
  isHowToFolder: PropTypes.bool,
};

export default VideoLibraryTable;
