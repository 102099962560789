import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { DND_VIDEO } from 'utils/constants';

function VideoRowDragWrapper({ videoId, children }) {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DND_VIDEO,
      videoId,
      element: React.cloneElement(children.props.children[0]),
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return React.cloneElement(children, {
    ref: drag,
    className: isDragging ? 'video-library-table__dragging-row' : '',
  });
}

export default VideoRowDragWrapper;
