import React, { createContext } from 'react';

import { EndNode,ParentNode } from './Nodes';

export const RoutingContext = createContext(null);

export const getChildNodes = (nodes) =>
  nodes.map(({ hide, ...node }) => {
    const Node = node.nodes ? ParentNode : EndNode;
    return hide ? null : <Node key={node.label} {...node} />;
  });
